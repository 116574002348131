import { ServiceFetch } from '../service-fetch'

export default class JournalRevenueListModel extends ServiceFetch {
  getJournalRevenueListBy = (data) => this.authFetch({
    url: 'journal-revenue-list/getJournalRevenueListBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getLastJournalRevenueListBy = (data) => this.authFetch({
    url: 'journal-revenue-list/getLastJournalRevenueListBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  generateJournalRevenueListBy = (data) => this.authFetch({
    url: 'journal-revenue-list/generateJournalRevenueListBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

}