import { ServiceFetch } from '../service-fetch'

export default class StockChangeModel extends ServiceFetch {
    generateStockChangeLastCode = (data) => this.authFetch({
        url: 'stock-change/generateStockChangeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockChangeBy = (data) => this.authFetch({
        url: 'stock-change/getStockChangeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockChangeByCode = (data) => this.authFetch({
        url: 'stock-change/getStockChangeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveStockChangeBy = (data) => this.authFetch({
        url: 'stock-change/approveStockChangeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockChangeBy = (data) => this.authFetch({
        url: 'stock-change/updateStockChangeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockChange = (data) => this.authFetch({
        url: 'stock-change/insertStockChange',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockChangeByCode = (data) => this.authFetch({
        url: 'stock-change/deleteStockChangeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}