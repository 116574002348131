import { ServiceFetch } from '../service-fetch'

export default class PromotionModel extends ServiceFetch {
    generatePromotionLastCode = (data) => this.authFetch({
        url: 'promotion/generatePromotionLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionBy = (data) => this.authFetch({
        url: 'promotion/getPromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getActivePromotionBy = (data) => this.authFetch({
        url: 'promotion/getActivePromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionByCode = (data) => this.authFetch({
        url: 'promotion/getPromotionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionByProductCode = (data) => this.authFetch({
        url: 'promotion/getPromotionByProductCode',
        method: 'POST',
        body: JSON.stringify(data),
    })


    checkDuplicate = (data) => this.authFetch({
        url: 'promotion/checkDuplicate',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkDuplicateRG = (data) => this.authFetch({
        url: 'promotion/checkDuplicateRG',
        method: 'POST',
        body: JSON.stringify(data),
    })


    approvePromotionBy = (data) => this.authFetch({
        url: 'promotion/approvePromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePromotionBy = (data) => this.authFetch({
        url: 'promotion/updatePromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPromotion = (data) => this.authFetch({
        url: 'promotion/insertPromotion',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePromotionByCode = (data) => this.authFetch({
        url: 'promotion/deletePromotionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}