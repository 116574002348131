import { ServiceFetch } from '../service-fetch'

export default class EmployeeWarningTypeModel extends ServiceFetch {
    generateEmployeeWarningTypeLastCode = (data) => this.authFetch({
        url: 'employee-warning-type/generateEmployeeWarningTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeWarningTypeBy = (data) => this.authFetch({
        url: 'employee-warning-type/getEmployeeWarningTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeWarningTypeByCode = (data) => this.authFetch({
        url: 'employee-warning-type/getEmployeeWarningTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateEmployeeWarningTypeBy = (data) => this.authFetch({
        url: 'employee-warning-type/updateEmployeeWarningTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertEmployeeWarningType = (data) => this.authFetch({
        url: 'employee-warning-type/insertEmployeeWarningType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteEmployeeWarningTypeByCode = (data) => this.authFetch({
        url: 'employee-warning-type/deleteEmployeeWarningTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
}