import { ServiceFetch } from '../service-fetch'

export default class ShortcutProductModel extends ServiceFetch {
    generateShortcutProductLastCode = (data) => this.authFetch({
        url: 'shortcut-product/generateShortcutProductLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getShortcutProductBy = (data) => this.authFetch({
        url: 'shortcut-product/getShortcutProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getShortcutProductByCode = (data) => this.authFetch({
        url: 'shortcut-product/getShortcutProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateShortcutProductBy = (data) => this.authFetch({
        url: 'shortcut-product/updateShortcutProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertShortcutProduct = (data) => this.authFetch({
        url: 'shortcut-product/insertShortcutProduct',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteShortcutProductByCode = (data) => this.authFetch({
        url: 'shortcut-product/deleteShortcutProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}