import { ServiceFetch } from '../service-fetch'

export default class ReceiptReturnListModel extends ServiceFetch {
    
    getReceiptReturnListBy = (data) => this.authFetch({
        url: 'receipt-return-list/getReceiptReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getReceiptCopyReturnListBy = (data) => this.authFetch({
        url: 'receipt-return-list/getReceiptCopyReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}