import { ServiceFetch } from '../service-fetch'

export default class CreditNoteSupplierOtherListModel extends ServiceFetch {
    getCreditNoteSupplierOtherListBy = (data) => this.authFetch({
        url: 'credit-note-supplier-other-list/getCreditNoteSupplierOtherListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateCreditNoteSupplierOtherListBy = (data) => this.authFetch({
        url: 'credit-note-supplier-other-list/generateCreditNoteSupplierOtherListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}