import { ServiceFetch } from '../service-fetch'

export default class ReimbursementModel extends ServiceFetch {
    generateReimbursementLastCode = (data) => this.authFetch({
        url: 'reimbursement/generateReimbursementLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReimbursementBy = (data) => this.authFetch({
        url: 'reimbursement/getReimbursementBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReimbursementByCode = (data) => this.authFetch({
        url: 'reimbursement/getReimbursementByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCurrentReimbursement = (data) => this.authFetch({
        url: 'reimbursement/getCurrentReimbursement',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReimbursement = (data) => this.authFetch({
        url: 'reimbursement/insertReimbursement',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveReimbursementBy = (data) => this.authFetch({
        url: 'reimbursement/approveReimbursementBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}