import { ServiceFetch } from '../service-fetch'

export default class GalleryModel extends ServiceFetch {
    generateGalleryLastCode = (data) => this.authFetch({
        url: 'gallery/generateGalleryLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getGalleryBy = (data) => this.authFetch({
        url: 'gallery/getGalleryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getGalleryByCode = (data) => this.authFetch({
        url: 'gallery/getGalleryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateGalleryBy = (data) => this.authFetch({
        url: 'gallery/updateGalleryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertGallery = (data) => this.authFetch({
        url: 'gallery/insertGallery',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteGalleryByCode = (data) => this.authFetch({
        url: 'gallery/deleteGalleryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}