import { ServiceFetch } from '../service-fetch'

export default class PurchaseOrderModel extends ServiceFetch {
    generatePurchaseOrderLastCode = (data) => this.authFetch({
        url: 'purchase-order/generatePurchaseOrderLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseOrderBy = (data) => this.authFetch({
        url: 'purchase-order/getPurchaseOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseOrderByCode = (data) => this.authFetch({
        url: 'purchase-order/getPurchaseOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseOrderProductBy = (data) => this.authFetch({
        url: 'purchase-order/getPurchaseOrderProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSupplierPurchaseOrder = (data) => this.authFetch({
        url: 'purchase-order/getAdviseSupplierPurchaseOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseProductPurchaseOrder = (data) => this.authFetch({
        url: 'purchase-order/getAdviseProductPurchaseOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })
    sendPurchaseOrderEmailByCode = (data) => this.authFetch({
        url: 'purchase-order/sendPurchaseOrderEmailByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    cancelPurchaseOrderByCode = (data) => this.authFetch({
        url: 'purchase-order/cancelPurchaseOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelPurchaseOrderByCode = (data) => this.authFetch({
        url: 'purchase-order/unCancelPurchaseOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approvePurchaseOrderBy = (data) => this.authFetch({
        url: 'purchase-order/approvePurchaseOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseOrderBy = (data) => this.authFetch({
        url: 'purchase-order/updatePurchaseOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPurchaseOrder = (data) => this.authFetch({
        url: 'purchase-order/insertPurchaseOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePurchaseOrderByCode = (data) => this.authFetch({
        url: 'purchase-order/deletePurchaseOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}