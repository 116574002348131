import { ServiceFetch } from '../service-fetch'

export default class SaleOrderListModel extends ServiceFetch {
    getSaleOrderListBy = (data) => this.authFetch({
        url: 'sale-order-list/getSaleOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSaleOrderListBy = (data) => this.authFetch({
        url: 'sale-order-list/generateSaleOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}