import { ServiceFetch } from '../service-fetch'

export default class DeliveryNoteListModel extends ServiceFetch {
    getDeliveryNoteListBy = (data) => this.authFetch({
        url: 'delivery-note-list/getDeliveryNoteListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDeliveryNoteListBy = (data) => this.authFetch({
        url: 'delivery-note-list/generateDeliveryNoteListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}