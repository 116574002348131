import { ServiceFetch } from '../service-fetch'

export default class ProductInitModel extends ServiceFetch {
    generateProductInitLastCode = (data) => this.authFetch({
        url: 'product-init/generateProductInitLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductInitBy = (data) => this.authFetch({
        url: 'product-init/getProductInitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductInit = (data) => this.authFetch({
        url: 'product-init/insertProductInit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductInitByCode = (data) => this.authFetch({
        url: 'product-init/deleteProductInitByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}