import { ServiceFetch } from '../service-fetch'

export default class OverTimeWorkModel extends ServiceFetch {
    generateOverTimeWorkLastCode = (data) => this.authFetch({
        url: 'over-time-work/generateOverTimeWorkLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getOverTimeWorkBy = (data) => this.authFetch({
        url: 'over-time-work/getOverTimeWorkBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getOverTimeWorkByCode = (data) => this.authFetch({
        url: 'over-time-work/getOverTimeWorkByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateOverTimeWorkBy = (data) => this.authFetch({
        url: 'over-time-work/updateOverTimeWorkBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveOverTimeWorkBy = (data) => this.authFetch({
        url: 'over-time-work/approveOverTimeWorkBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertOverTimeWork = (data) => this.authFetch({
        url: 'over-time-work/insertOverTimeWork',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteOverTimeWorkByCode = (data) => this.authFetch({
        url: 'over-time-work/deleteOverTimeWorkByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}