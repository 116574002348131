import { ServiceFetch } from '../service-fetch'

export default class CreditNoteCustomerListModel extends ServiceFetch {
    getCreditNoteCustomerListBy = (data) => this.authFetch({
        url: 'credit-note-customer-list/getCreditNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateCreditNoteCustomerListBy = (data) => this.authFetch({
        url: 'credit-note-customer-list/generateCreditNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}