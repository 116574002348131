import { ServiceFetch } from '../service-fetch'

export default class PurchaseOrderListModel extends ServiceFetch {
    getPurchaseOrderListBy = (data) => this.authFetch({
        url: 'purchase-order-list/getPurchaseOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseOrderListProductSupplierBy = (data) => this.authFetch({
        url: 'purchase-order-list/getPurchaseOrderListProductSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getHistoryPurchaseOrderListBy = (data) => this.authFetch({
        url: 'purchase-order-list/getHistoryPurchaseOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generatePurchaseOrderListBy = (data) => this.authFetch({
        url: 'purchase-order-list/generatePurchaseOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseOrderListCloseBy = (data) => this.authFetch({
        url: 'purchase-order-list/updatePurchaseOrderListCloseBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
}