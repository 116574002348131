import { ServiceFetch } from '../service-fetch'

export default class OrderModel extends ServiceFetch {
  generateOrderLastCode = (data) => this.authFetch({
    url: 'order/generateOrderLastCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getOrderBy = (data) => this.authFetch({
    url: 'order/getOrderBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getOrderByCode = (data) => this.authFetch({
    url: 'order/getOrderByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  UpdateOrderStatusByCode = (data) => this.authFetch({
    url: 'order/UpdateOrderStatusByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  updateOrderBy = (data) => this.authFetch({
    url: 'order/updateOrderBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  requestPrintCoverSheet = (data) => this.authFetch({
    url: 'order/requestPrintCoverSheet',
    method: 'POST',
    body: JSON.stringify(data),
  })

  ApprovePrintCoverSheet = (data) => this.authFetch({
    url: 'order/ApprovePrintCoverSheet',
    method: 'POST',
    body: JSON.stringify(data),
  })

  insertOrder = (data) => this.authFetch({
    url: 'order/insertOrder',
    method: 'POST',
    body: JSON.stringify(data),
  })

  increaseOrderPrintAddress = (data) => this.authFetch({
    url: 'order/increaseOrderPrintAddress',
    method: 'POST',
    body: JSON.stringify(data),
  })

  deleteOrderByCode = (data) => this.authFetch({
    url: 'order/deleteOrderByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })
}