import { ServiceFetch } from '../service-fetch'

export default class FactStockMoveModel extends ServiceFetch {
    getAdviseStockRequestMoveBy = (data) => this.authFetch({
        url: 'fact-stock-move/getAdviseStockRequestMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseStockGroupMoveBy = (data) => this.authFetch({
        url: 'fact-stock-move/getAdviseStockGroupMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}