import { ServiceFetch } from '../service-fetch'

export default class DepartmentModel extends ServiceFetch {
    generateDepartmentLastCode = (data) => this.authFetch({
        url: 'department/generateDepartmentLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDepartmentBy = (data) => this.authFetch({
        url: 'department/getDepartmentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDepartmentByCode = (data) => this.authFetch({
        url: 'department/getDepartmentByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDepartmentBy = (data) => this.authFetch({
        url: 'department/updateDepartmentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDepartment = (data) => this.authFetch({
        url: 'department/insertDepartment',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDepartmentByCode = (data) => this.authFetch({
        url: 'department/deleteDepartmentByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}