import { ServiceFetch } from '../service-fetch'

export default class FactStockPoorModel extends ServiceFetch {

    getFactStockPoorBy = (data) => this.authFetch({
        url: 'fact-stock-poor/getFactStockPoorBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}