import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierMaterialDraftModel extends ServiceFetch {
    generateInvoiceSupplierMaterialDraftLastCode = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/generateInvoiceSupplierMaterialDraftLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierMaterialDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/getInvoiceSupplierMaterialDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierMaterialDraftByCode = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/getInvoiceSupplierMaterialDraftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierMaterialDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/updateInvoiceSupplierMaterialDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 

    insertInvoiceSupplierMaterialDraft = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/insertInvoiceSupplierMaterialDraft',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceSupplierMaterialDraftByCode = (data) => this.authFetch({
        url: 'invoice-supplier-material-draft/deleteInvoiceSupplierMaterialDraftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}