import { ServiceFetch } from '../service-fetch'

export default class InvoiceCustomerModel extends ServiceFetch {
    generateInvoiceCustomerLastCode = (data) => this.authFetch({
        url: 'invoice-customer/generateInvoiceCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSaleOrderInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getAdviseSaleOrderInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCustomerInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getAdviseCustomerInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSaleOrderByCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceSaleOrderByCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })
    updateInvoiceCustomerPrint = (data) => this.authFetch({
        url: 'invoice-customer/updateInvoiceCustomerPrint',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    cancelInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/cancelInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/unCancelInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer/updateInvoiceCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/insertInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/deleteInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchasesDataBy = (data) => this.authFetch({
        url: 'invoice-customer/getPurchasesDataBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBestSellerDataBy = (data) => this.authFetch({
        url: 'invoice-customer/getBestSellerDataBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseProfitBy = (data) => this.authFetch({
        url: 'invoice-customer/getPurchaseProfitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseIncomeAllCompanyBranchBy = (data) => this.authFetch({
        url: 'invoice-customer/getPurchaseIncomeAllCompanyBranchBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseIncomeByMonthYearBy = (data) => this.authFetch({
        url: 'invoice-customer/getPurchaseIncomeByMonthYearBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}