import { ServiceFetch } from '../service-fetch'

export default class StockRequestMoveMainModel extends ServiceFetch {
    generateStockRequestMoveMainLastCode = (data) => this.authFetch({
        url: "stock-request-move-main/generateStockRequestMoveMainLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockRequestMoveMainBy = (data) => this.authFetch({
        url: "stock-request-move-main/getStockRequestMoveMainBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockRequestMoveMainByCode = (data) => this.authFetch({
        url: "stock-request-move-main/getStockRequestMoveMainByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    approveStockRequestMoveMainBy = (data) => this.authFetch({
        url: "stock-request-move-main/approveStockRequestMoveMainBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateStockRequestMoveMainBy = (data) => this.authFetch({
        url: "stock-request-move-main/updateStockRequestMoveMainBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertStockRequestMoveMain = (data) => this.authFetch({
        url: "stock-request-move-main/insertStockRequestMoveMain",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteStockRequestMoveMainByCode = (data) => this.authFetch({
        url: "stock-request-move-main/deleteStockRequestMoveMainByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}
