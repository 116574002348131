import { ServiceFetch } from '../service-fetch'

export default class StockReturnListModel extends ServiceFetch {
    getStockReturnListBy = (data) => this.authFetch({
        url: 'stock-return-list/getStockReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockReturnListBy = (data) => this.authFetch({
        url: 'stock-return-list/generateStockReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}