import { ServiceFetch } from '../service-fetch'

export default class SavingsModel extends ServiceFetch {
    generateSavingsLastCode = (data) => this.authFetch({
        url: "savings/generateSavingsLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSavingsBy = (data) => this.authFetch({
        url: "savings/getSavingsBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSavingsByCode = (data) => this.authFetch({
        url: "savings/getSavingsByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getLastSavingsByCode = (data) => this.authFetch({
        url: "savings/getLastSavingsByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    approveSavingsBy = (data) => this.authFetch({
        url: "savings/approveSavingsBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSignatureByCode = (data) => this.authFetch({
        url: "savings/updateSignatureByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSavingsBy = (data) => this.authFetch({
        url: "savings/updateSavingsBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertSavings = (data) => this.authFetch({
        url: "savings/insertSavings",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSavingsByCode = (data) => this.authFetch({
        url: "savings/deleteSavingsByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}