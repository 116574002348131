import { ServiceFetch } from '../service-fetch'

export default class SalesmanModel extends ServiceFetch {
    generateSalesmanLastCode = (data) => this.authFetch({
        url: "salesman/generateSalesmanLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSalesmanBy = (data) => this.authFetch({
        url: 'salesman/getSalesmanBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalesmanByCode = (data) => this.authFetch({
        url: 'salesman/getSalesmanByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkUsernameBy = (data) => this.authFetch({
        url: 'salesman/checkUsernameBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSalesmanBy = (data) => this.authFetch({
        url: 'salesman/updateSalesmanBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSalesman = (data) => this.authFetch({
        url: 'salesman/insertSalesman',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSalesmanByCode = (data) => this.authFetch({
        url: 'salesman/deleteSalesmanByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}