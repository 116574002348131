import { ServiceFetch } from '../service-fetch'

export default class DebitReceiptModel extends ServiceFetch {
    generateDebitReceiptLastCode = (data) => this.authFetch({
        url: 'debit-receipt/generateDebitReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitReceiptBy = (data) => this.authFetch({
        url: 'debit-receipt/getDebitReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitReceiptByCode = (data) => this.authFetch({
        url: 'debit-receipt/getDebitReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    showDebitReceiptByCode = (data) => this.authFetch({
        url: 'debit-receipt/showDebitReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    hideDebitReceiptByCode = (data) => this.authFetch({
        url: 'debit-receipt/hideDebitReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitReceiptBy = (data) => this.authFetch({
        url: 'debit-receipt/updateDebitReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitReceipt = (data) => this.authFetch({
        url: 'debit-receipt/insertDebitReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitReceiptByCode = (data) => this.authFetch({
        url: 'debit-receipt/deleteDebitReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}