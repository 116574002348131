import { ServiceFetch } from '../service-fetch'

export default class PurchaseReturnModel extends ServiceFetch {
    generatePurchaseReturnLastCode = (data) => this.authFetch({
        url: 'purchase-return/generatePurchaseReturnLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseReturnBy = (data) => this.authFetch({
        url: 'purchase-return/getPurchaseReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseReturnByCode = (data) => this.authFetch({
        url: 'purchase-return/getPurchaseReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseReturnBy = (data) => this.authFetch({
        url: 'purchase-return/updatePurchaseReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseReturnDeliveryBy = (data) => this.authFetch({
        url: 'purchase-return/updatePurchaseReturnDeliveryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSupplierReplyBy = (data) => this.authFetch({
        url: 'purchase-return/updateSupplierReplyBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPurchaseReturn = (data) => this.authFetch({
        url: 'purchase-return/insertPurchaseReturn',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePurchaseReturnByCode = (data) => this.authFetch({
        url: 'purchase-return/deletePurchaseReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}