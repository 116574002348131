import { env } from '../config/global'
import { token } from '../config/access-token'

export class ServiceFetch {
    directFetch = (data) => fetch(`${env.service_url}${data.url}`, {
        method: data.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data.body,
    }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))


    authFetch = async (data) => { 
        const response = await fetch(`${env.service_url}${data.url}`, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token['x-access-token'],
            },
            
            body: data.body,
        }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

        if (response.unauthorized) {
            console.log('unauthorized', response.error)

            localStorage.clear()
            window.location.reload()
        }

        return response
    }
}