import { ServiceFetch } from '../service-fetch'

export default class ProductGroupDiscoundModel extends ServiceFetch {
    generateProductGroupDiscoundLastCode = (data) => this.authFetch({
        url: 'product-group-discound/generateProductGroupDiscoundLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductGroupDiscoundBy = (data) => this.authFetch({
        url: 'product-group-discound/getProductGroupDiscoundBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductGroupDiscoundByCode = (data) => this.authFetch({
        url: 'product-group-discound/getProductGroupDiscoundByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductGroupDiscoundBy = (data) => this.authFetch({
        url: 'product-group-discound/updateProductGroupDiscoundBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductGroupDiscound = (data) => this.authFetch({
        url: 'product-group-discound/insertProductGroupDiscound',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductGroupDiscoundByCode = (data) => this.authFetch({
        url: 'product-group-discound/deleteProductGroupDiscoundByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}