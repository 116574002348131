import { ServiceFetch } from '../service-fetch'

export default class StockReturnModel extends ServiceFetch {
    generateStockReturnLastCode = (data) => this.authFetch({
        url: 'stock-return/generateStockReturnLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReturnBy = (data) => this.authFetch({
        url: 'stock-return/getStockReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReturnByCode = (data) => this.authFetch({
        url: 'stock-return/getStockReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockReturnBy = (data) => this.authFetch({
        url: 'stock-return/updateStockReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockReturn = (data) => this.authFetch({
        url: 'stock-return/insertStockReturn',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockReturnByCode = (data) => this.authFetch({
        url: 'stock-return/deleteStockReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}