import { ServiceFetch } from '../service-fetch'

export default class FinanceCreditListModel extends ServiceFetch {

    generateFactFinanceCredit = (data) => this.authFetch({
        url: 'fact-finance-credit/generateFactFinanceCredit',
        method: 'POST',
        body: JSON.stringify(data),
    })
    UpdateFactFinanceCredit = (data) => this.authFetch({
        url: 'fact-finance-credit/UpdateFactFinanceCredit',
        method: 'POST',
        body: JSON.stringify(data),
    })

}