import { ServiceFetch } from '../service-fetch'

export default class UserPositionModel extends ServiceFetch {
    generateEmployeePositionLastCode = (data) => this.authFetch({
        url: 'employee-position/generateEmployeePositionLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeePositionBy = (data) => this.authFetch({
        url: 'employee-position/getEmployeePositionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeePositionByCode = (data) => this.authFetch({
        url: 'employee-position/getEmployeePositionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateEmployeePositionBy = (data) => this.authFetch({
        url: 'employee-position/updateEmployeePositionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertEmployeePosition = (data) => this.authFetch({
        url: 'employee-position/insertEmployeePosition',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteEmployeePositionByCode = (data) => this.authFetch({
        url: 'employee-position/deleteEmployeePositionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}