import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierListFreightModel extends ServiceFetch {
    getInvoiceSupplierListFreightBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-freight/getInvoiceSupplierListFreightBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierListFreightBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-freight/getLastInvoiceSupplierListFreightBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierListFreightBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-freight/generateInvoiceSupplierListFreightBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}