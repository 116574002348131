import { ServiceFetch } from '../service-fetch'

export default class PolicyModel extends ServiceFetch {
    // generateShiftWorkLastCode = (data) => this.authFetch({
    //     url: 'policy/generateShiftWorkLastCode',
    //     method: 'POST',
    //     body: JSON.stringify(data),
    // })

    getPolicyBy = (data) => this.authFetch({
        url: 'policy/getPolicyBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPolicyByCode = (data) => this.authFetch({
        url: 'policy/getPolicyByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePolicyBy = (data) => this.authFetch({
        url: 'policy/updatePolicyBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPolicy = (data) => this.authFetch({
        url: 'policy/insertPolicy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePolicyByCode = (data) => this.authFetch({
        url: 'policy/deletePolicyByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}