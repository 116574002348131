import { ServiceFetch } from '../service-fetch'

export default class DebitNoteSupplierListModel extends ServiceFetch {
    getDebitNoteSupplierListBy = (data) => this.authFetch({
        url: 'debit-note-supplier-list/getDebitNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDebitNoteSupplierListBy = (data) => this.authFetch({
        url: 'debit-note-supplier-list/generateDebitNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}