import { ServiceFetch } from '../service-fetch'

export default class PromotionTypeModel extends ServiceFetch {
    getPromotionTypeBy = (data) => this.authFetch({
        url: 'promotion-type/getPromotionTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionTypeByCode = (data) => this.authFetch({
        url: 'promotion-type/getPromotionTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}