import { ServiceFetch } from '../service-fetch'

export default class LeaveModel extends ServiceFetch {
    generateLeaveLastCode = (data) => this.authFetch({
        url: 'leave/generateLeaveLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLeaveBy = (data) => this.authFetch({
        url: 'leave/getLeaveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLeaveByCode = (data) => this.authFetch({
        url: 'leave/getLeaveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateLeaveBy = (data) => this.authFetch({
        url: 'leave/updateLeaveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertLeave = (data) => this.authFetch({
        url: 'leave/insertLeave',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteLeaveByCode = (data) => this.authFetch({
        url: 'leave/deleteLeaveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveLeaveBy = (data) => this.authFetch({
        url: 'leave/approveLeaveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}