import { ServiceFetch } from '../service-fetch'

export default class ProductTypeModel extends ServiceFetch {
    getProductTypeBy = (data) => this.authFetch({
        url: 'product-type/getProductTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateProductTypeLastCode = (data) => this.authFetch({
        url: 'product-type/generateProductTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductTypeByCode = (data) => this.authFetch({
        url: 'product-type/getProductTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductTypeBy = (data) => this.authFetch({
        url: 'product-type/updateProductTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductType = (data) => this.authFetch({
        url: 'product-type/insertProductType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductTypeByCode = (data) => this.authFetch({
        url: 'product-type/deleteProductTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}