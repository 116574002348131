import { ServiceFetch } from '../service-fetch'

export default class CreditNoteSupplierOtherModel extends ServiceFetch {
    generateCreditNoteSupplierOtherLastCode = (data) => this.authFetch({
        url: 'credit-note-supplier-other/generateCreditNoteSupplierOtherLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteSupplierOtherBy = (data) => this.authFetch({
        url: 'credit-note-supplier-other/getCreditNoteSupplierOtherBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteSupplierOtherByCode = (data) => this.authFetch({
        url: 'credit-note-supplier-other/getCreditNoteSupplierOtherByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCreditNoteSupplierOtherBy = (data) => this.authFetch({
        url: 'credit-note-supplier-other/updateCreditNoteSupplierOtherBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCreditNoteSupplierOther = (data) => this.authFetch({
        url: 'credit-note-supplier-other/insertCreditNoteSupplierOther',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCreditNoteSupplierOtherByCode = (data) => this.authFetch({
        url: 'credit-note-supplier-other/deleteCreditNoteSupplierOtherByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}