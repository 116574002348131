import { ServiceFetch } from '../service-fetch'

export default class SalaryPaymentModel extends ServiceFetch {
    generateSalaryPaymentLastCode = (data) => this.authFetch({
        url: 'salary-payment/generateSalaryPaymentLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalaryPaymentBy = (data) => this.authFetch({
        url: 'salary-payment/getSalaryPaymentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalaryPaymentByCode = (data) => this.authFetch({
        url: 'salary-payment/getSalaryPaymentByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSalaryPaymentBy = (data) => this.authFetch({
        url: 'salary-payment/updateSalaryPaymentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSalaryPayment = (data) => this.authFetch({
        url: 'salary-payment/insertSalaryPayment',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSalaryPaymentByCode = (data) => this.authFetch({
        url: 'salary-payment/deleteSalaryPaymentByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveSalaryPaymentBy = (data) => this.authFetch({
        url: 'salary-payment/approveSalaryPaymentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}