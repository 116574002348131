import { ServiceFetch } from '../service-fetch'

export default class ReceiptDiscountListModel extends ServiceFetch {
    getReceiptDiscountListBy = (data) => this.authFetch({
        url: 'receipt-discount-list/getReceiptDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptDiscountDetailListBy = (data) => this.authFetch({
        url: 'receipt-discount-list/getReceiptDiscountDetailListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getReceiptCopyDiscountListBy = (data) => this.authFetch({
        url: 'receipt-discount-list/getReceiptCopyDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseDiscountListByCode = (data) => this.authFetch({
        url: 'receipt-discount-list/increaseDiscountListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    
    getReceiptDiscountDC = (data) => this.authFetch({
        url: 'receipt-discount-list/getReceiptDiscountDC',
        method: 'POST',
        body: JSON.stringify(data),
    })
}