import { ServiceFetch } from '../service-fetch'

export default class AssetTypeModel extends ServiceFetch {

    generateAssetTypeLastCode = (data) => this.authFetch({
        url: 'asset-type/generateAssetTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAssetTypeBy = (data) => this.authFetch({
        url: 'asset-type/getAssetTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAssetTypeByCode = (data) => this.authFetch({
        url: 'asset-type/getAssetTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateAssetTypeByCode = (data) => this.authFetch({
        url: 'asset-type/updateAssetTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertAssetType = (data) => this.authFetch({
        url: 'asset-type/insertAssetType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteAssetTypeByCode = (data) => this.authFetch({
        url: 'asset-type/deleteAssetTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

}