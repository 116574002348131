import { ServiceFetch } from '../service-fetch'

export default class DeliveryNoteDiscountListModel extends ServiceFetch {
    getDeliveryNoteDiscountListBy = (data) => this.authFetch({
        url: 'delivery-note-discount-list/getDeliveryNoteDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDeliveryNoteDiscountListBy = (data) => this.authFetch({
        url: 'delivery-note-discount-list/generateDeliveryNoteDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}