import { ServiceFetch } from '../service-fetch'

export default class ReceiptRequestCancelModel extends ServiceFetch {
    generateReceiptRequestCancelLastCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/generateReceiptRequestCancelLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptRequestCancelBy = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptRequestCancelByCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveReceiptRequestCancelByCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/approveReceiptRequestCancelByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
  
    insertReceiptRequestCancel = (data) => this.authFetch({
        url: 'receipt-request-cancel/insertReceiptRequestCancel',
        method: 'POST',
        body: JSON.stringify(data),
    })
}