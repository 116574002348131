import { ServiceFetch } from '../service-fetch'

export default class PaperLockModel extends ServiceFetch {
    getPaperLockBy = (data) => this.authFetch({
        url: 'paper-lock/getPaperLockBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePaperLockBy = (data) => this.authFetch({
        url: 'paper-lock/updatePaperLockBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createPaperLock = (data) => this.authFetch({
        url: 'paper-lock/createPaperLock',
        method: 'POST',
        body: JSON.stringify(data),
    })
}