import { ServiceFetch } from '../service-fetch'

export default class StockMoveMainModel extends ServiceFetch {
    generateStockMoveMainLastCode = (data) => this.authFetch({
        url: 'stock-move-main/generateStockMoveMainLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveMainBy = (data) => this.authFetch({
        url: 'stock-move-main/getStockMoveMainBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveMainByCode = (data) => this.authFetch({
        url: 'stock-move-main/getStockMoveMainByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockMoveMainBy = (data) => this.authFetch({
        url: 'stock-move-main/updateStockMoveMainBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveStockMoveMainBy = (data) => this.authFetch({
        url: 'stock-move-main/approveStockMoveMainBy',
        method: 'POST',
        body: JSON.stringify(data),
    })


    insertStockMoveMain = (data) => this.authFetch({
        url: 'stock-move-main/insertStockMoveMain',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockMoveMainByCode = (data) => this.authFetch({
        url: 'stock-move-main/deleteStockMoveMainByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}