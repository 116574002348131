import { ServiceFetch } from '../service-fetch'

export default class NewsModel extends ServiceFetch {
    // generateShiftWorkLastCode = (data) => this.authFetch({
    //     url: 'policy/generateShiftWorkLastCode',
    //     method: 'POST',
    //     body: JSON.stringify(data),
    // })

    getNewsBy = (data) => this.authFetch({
        url: 'news/getNewsBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getNewsByCode = (data) => this.authFetch({
        url: 'news/getNewsByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateNewsBy = (data) => this.authFetch({
        url: 'news/updateNewsBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertNews = (data) => this.authFetch({
        url: 'news/insertNews',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteNewsByCode = (data) => this.authFetch({
        url: 'news/deleteNewsByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}