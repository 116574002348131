import { ServiceFetch } from '../service-fetch'

export default class PurchaseReturnListModel extends ServiceFetch {
    getPurchaseReturnListBy = (data) => this.authFetch({
        url: 'purchase-return-list/getPurchaseReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    generatePurchaseReturnListBy = (data) => this.authFetch({
        url: 'purchase-return-list/generatePurchaseReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}