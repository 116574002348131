import { ServiceFetch } from '../service-fetch'

export default class CompanyInfoModel extends ServiceFetch {
    getCompanyInfo = (data) => this.authFetch({
        url: 'company-info/getCompanyInfo',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoAboutUsBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoAboutUsBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoHowToOrderBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoHowToOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    updateCompanyInfoContactBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoContactBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    updateCompanyInfoRecruitmentBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoRecruitmentBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoPaymentMethodBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoPaymentMethodBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCartStatusBy = (data) => this.authFetch({
        url: 'company-info/updateCartStatusBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoSavingBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoSavingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}