import { ServiceFetch } from '../service-fetch'

export default class FinanceDebitModel extends ServiceFetch {
    generateFinanceDebitLastCode = (data) => this.authFetch({
        url: 'finance-debit/generateFinanceDebitLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceDebitBy = (data) => this.authFetch({
        url: 'finance-debit/getFinanceDebitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceDebitByCode = (data) => this.authFetch({
        url: 'finance-debit/getFinanceDebitByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCustomerFinanceDebit = (data) => this.authFetch({
        url: 'finance-debit/getAdviseCustomerFinanceDebit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateFinanceDebitBy = (data) => this.authFetch({
        url: 'finance-debit/updateFinanceDebitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertFinanceDebit = (data) => this.authFetch({
        url: 'finance-debit/insertFinanceDebit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteFinanceDebitByCode = (data) => this.authFetch({
        url: 'finance-debit/deleteFinanceDebitByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}