import { ServiceFetch } from '../service-fetch'

export default class AccountModel extends ServiceFetch {
    generateAccountLastCode = (data) => this.authFetch({
        url: 'account/generateAccountLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAccountBy = (data) => this.authFetch({
        url: 'account/getAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAccountByCode = (data) => this.authFetch({
        url: 'account/getAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getAccountJournalTypeBy = (data) => this.authFetch({
        url: 'account/getAccountJournalTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAccountIncomeStatementBy = (data) => this.authFetch({
        url: 'account/getAccountIncomeStatementBys',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateAccountBy = (data) => this.authFetch({
        url: 'account/updateAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateAccountCarryBy = (data) => this.authFetch({
        url: 'account/updateAccountCarryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createAccount = (data) => this.authFetch({
        url: 'account/createAccount',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertAccount = (data) => this.authFetch({
        url: 'account/insertAccount',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteAccountByCode = (data) => this.authFetch({
        url: 'account/deleteAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}