import { ServiceFetch } from '../service-fetch'

export default class StockPackModel extends ServiceFetch {
    generateStockPackLastCode = (data) => this.authFetch({
        url: 'stock-pack/generateStockPackLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockPackBy = (data) => this.authFetch({
        url: 'stock-pack/getStockPackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockPackByCode = (data) => this.authFetch({
        url: 'stock-pack/getStockPackByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockPackBy = (data) => this.authFetch({
        url: 'stock-pack/updateStockPackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockPack = (data) => this.authFetch({
        url: 'stock-pack/insertStockPack',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockPackByCode = (data) => this.authFetch({
        url: 'stock-pack/deleteStockPackByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}