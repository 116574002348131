import { ServiceFetch } from '../service-fetch'

export default class WithdrawalModel extends ServiceFetch {
    generateWithdrawalLastCode = (data) => this.authFetch({
        url: "withdrawal/generateWithdrawalLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getWithdrawalBy = (data) => this.authFetch({
        url: "withdrawal/getWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getWithdrawalByCode = (data) => this.authFetch({
        url: "withdrawal/getWithdrawalByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    requestUpdateBy = (data) => this.authFetch({
        url: "withdrawal/requestUpdateBy",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    approveWithdrawalBy = (data) => this.authFetch({
        url: "withdrawal/approveWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateWithdrawalBy = (data) => this.authFetch({
        url: "withdrawal/updateWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertWithdrawal = (data) => this.authFetch({
        url: "withdrawal/insertWithdrawal",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteWithdrawalByCode = (data) => this.authFetch({
        url: "withdrawal/deleteWithdrawalByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}