import { ServiceFetch } from '../service-fetch'

export default class DeliveryNoteModel extends ServiceFetch {
    generateDeliveryNoteLastCode = (data) => this.authFetch({
        url: 'delivery-note/generateDeliveryNoteLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDeliveryNoteBy = (data) => this.authFetch({
        url: 'delivery-note/getDeliveryNoteBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDeliveryNoteByCode = (data) => this.authFetch({
        url: 'delivery-note/getDeliveryNoteByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDeliveryNoteBy = (data) => this.authFetch({
        url: 'delivery-note/updateDeliveryNoteBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDeliveryNote = (data) => this.authFetch({
        url: 'delivery-note/insertDeliveryNote',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDeliveryNoteByCode = (data) => this.authFetch({
        url: 'delivery-note/deleteDeliveryNoteByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}