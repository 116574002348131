import { ServiceFetch } from '../service-fetch'

export default class BorrowModel extends ServiceFetch {
    generateBorrowLastCode = (data) => this.authFetch({
        url: "borrow/generateBorrowLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getBorrowBy = (data) => this.authFetch({
        url: "borrow/getBorrowBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getBorrowByCode = (data) => this.authFetch({
        url: "borrow/getBorrowByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    updateSignatureByCode = (data) => this.authFetch({
        url: "borrow/updateSignatureByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    approveBorrowBy = (data) => this.authFetch({
        url: "borrow/approveBorrowBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateBorrowBy = (data) => this.authFetch({
        url: "borrow/updateBorrowBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertBorrow = (data) => this.authFetch({
        url: "borrow/insertBorrow",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteBorrowByCode = (data) => this.authFetch({
        url: "borrow/deleteBorrowByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}