import { ServiceFetch } from '../service-fetch'

export default class ChequePayModel extends ServiceFetch {
    generateChequePayLastCode = (data) => this.authFetch({
        url: 'cheque-pay/generateChequePayLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChequePayBy = (data) => this.authFetch({
        url: 'cheque-pay/getChequePayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChequePayByCode = (data) => this.authFetch({
        url: 'cheque-pay/getChequePayByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateChequePayBy = (data) => this.authFetch({
        url: 'cheque-pay/generateChequePayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkChequePayNumberBy = (data) => this.authFetch({
        url: 'cheque-pay/checkChequePayNumberBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateNotPassChequePayByCode = (data) => this.authFetch({
        url: 'cheque-pay/updateNotPassChequePayByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateUnPassChequePayByCode = (data) => this.authFetch({
        url: 'cheque-pay/updateUnPassChequePayByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePassChequePayBy = (data) => this.authFetch({
        url: 'cheque-pay/updatePassChequePayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateChequePayBy = (data) => this.authFetch({
        url: 'cheque-pay/updateChequePayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertChequePay = (data) => this.authFetch({
        url: 'cheque-pay/insertChequePay',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteChequePayByCode = (data) => this.authFetch({
        url: 'cheque-pay/deleteChequePayByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}