import { ServiceFetch } from '../service-fetch'

export default class ReceiptSaleDepositListModel extends ServiceFetch {
    getReceiptSaleDepositListBy = (data) => this.authFetch({
        url: 'receipt-sale-deposit-list/getReceiptSaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptCopySaleDepositListBy = (data) => this.authFetch({
        url: 'receipt-sale-deposit-list/getReceiptCopySaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}