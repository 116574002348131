import { ServiceFetch } from '../service-fetch'

export default class DebitNoteReceiptModel extends ServiceFetch {
    generateDebitNoteReceiptLastCode = (data) => this.authFetch({
        url: 'debit-note-receipt/generateDebitNoteReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDebitNoteReceiptListBy = (data) => this.authFetch({
        url: 'debit-note-receipt/generateDebitNoteReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getDebitNoteReceiptBy = (data) => this.authFetch({
        url: 'debit-note-receipt/getDebitNoteReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteReceiptByCode = (data) => this.authFetch({
        url: 'debit-note-receipt/getDebitNoteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })


    approveDebitNoteReceiptBy = (data) => this.authFetch({
        url: 'debit-note-receipt/approveDebitNoteReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitNoteReceiptBy = (data) => this.authFetch({
        url: 'debit-note-receipt/updateDebitNoteReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitNoteReceipt = (data) => this.authFetch({
        url: 'debit-note-receipt/insertDebitNoteReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitNoteReceiptByCode = (data) => this.authFetch({
        url: 'debit-note-receipt/deleteDebitNoteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}