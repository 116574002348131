import { createStore } from 'redux'

const d_start = new Date()
const d_end = new Date()
    
d_start.setHours(0, 0)
d_end.setHours(23, 59)

const initialState = {
  date_start: d_start,
  date_end: d_end,
  sidebarShow: 'responsive',
  player: [],
}

const changeState = (state = initialState, { type, ...rest }) => {
  if (rest.date_start) rest = {}
  if (rest.date_end) rest = {}

  switch (type) {
  case 'set':
    return { ...state, ...rest }
  default:
    return state
  }
}

const store = createStore(changeState)
export default store