export const env = {

  service_url: process.env.REACT_APP_SERVICE_URL,
  export_url: process.env.REACT_APP_EXPORT_URL,
  socket_io_url: process.env.REACT_APP_SOCKET_IO_URL,
  socket_io_path: process.env.REACT_APP_SOCKET_IO_PATH,
  pos_url: process.env.REACT_APP_POS_URL,

  // local ---------------------------------------------------------
  // service_url: 'http://localhost:6201/',
  // export_url: 'http://localhost/giftshop/giftshop_export/',
  // socket_io_url: 'http://localhost:6200/',
  // socket_io_path: '',

  // develop -------------------------------------------------------
  // service_url: 'https://rvscs-develop.com/km-korat/',
  // export_url: 'http://giftshop-export.rvscs.com/', 
  // socket_io_url: 'ws://rvscs-develop.com/',
  // socket_io_path: '/km-korat-socket/socket.io/',

  // production ----------------------------------------------------
  // service_url: 'https://rvscs-prod.com/giftshop-service/',
  // export_url: 'https://giftshop-export.rvscs-service.com/',
  // socket_io_url: 'wss://rvscs-prod.com/',
  // socket_io_path: '/giftshop-socket-service/socket.io/',

  // new production -----------------------------------------------
  // service_url: 'https://rvstm.com/giftshop-service/',
  
  // socket_io_url: 'https://rvstm.com',
  // socket_io_path: '/giftshop-socket-service/socket.io/',

  // image_url: 'https://rvstm.com/giftshop-service',

  // pos_url: 'https://giftshop-pos.rvscs-service.com/#/',

  // export_url: 'https://rvstm.com/ge_2/',


}