import { ServiceFetch } from '../service-fetch'

export default class TeamModel extends ServiceFetch {
    generateTeamLastCode = (data) => this.authFetch({
        url: 'team/generateTeamLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getTeamBy = (data) => this.authFetch({
        url: 'team/getTeamBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getTeamByCode = (data) => this.authFetch({
        url: 'team/getTeamByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateTeamBy = (data) => this.authFetch({
        url: 'team/updateTeamBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertTeam = (data) => this.authFetch({
        url: 'team/insertTeam',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteTeamByCode = (data) => this.authFetch({
        url: 'team/deleteTeamByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}