import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierMaterialListDraftModel extends ServiceFetch {
    getInvoiceSupplierMaterialListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list-draft/getInvoiceSupplierMaterialListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierMaterialListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list-draft/getLastInvoiceSupplierMaterialListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierMaterialListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list-draft/generateInvoiceSupplierMaterialListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}