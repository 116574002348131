import { ServiceFetch } from '../service-fetch'

export default class FinanceCreditListModel extends ServiceFetch {
    getFinanceCreditListBy = (data) => this.authFetch({
        url: 'finance-credit-list/getFinanceCreditListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getFinanceCreditListByMany = (data) => this.authFetch({
        url: 'finance-credit-list/getFinanceCreditListByMany',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateFinanceCreditListBy = (data) => this.authFetch({
        url: 'finance-credit-list/generateFinanceCreditListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    generateFinanceCreditListByBillingNote = (data) => this.authFetch({
        url: 'finance-credit-list/generateFinanceCreditListByBillingNote',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateFinanceCreditListByPettyCashWithdrawal = (data) => this.authFetch({
        url: 'finance-credit-list/generateFinanceCreditListByPettyCashWithdrawal',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
}