import { ServiceFetch } from '../service-fetch'

export default class StockReportModel extends ServiceFetch {
    getStockReportListBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportBalanceListBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportBalanceListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportByStockCodeAndProductCode = (data) => this.authFetch({
        url: 'stock-report/getStockReportByStockCodeAndProductCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductPaperByCode = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductPaperByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportBalanceBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportMinPointBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportMinPointBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductDescriptionBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductDescriptionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductMovementDayBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductMovementDayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductNoMovementBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductNoMovementBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductMovementBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductMovementBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockReportProductBy = (data) => this.authFetch({
        url: 'stock-report/getStockReportProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockCostBy = (data) => this.authFetch({
        url: 'stock-report/getStockCostBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}