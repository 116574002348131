import { ServiceFetch } from '../service-fetch'

export default class SaleReturnModel extends ServiceFetch {
    generateSaleReturnLastCode = (data) => this.authFetch({
        url: 'sale-return/generateSaleReturnLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSalereturnListBy = (data) => this.authFetch({
        url: 'sale-return/generateSalereturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getSaleReturnBy = (data) => this.authFetch({
        url: 'sale-return/getSaleReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleReturnByCode = (data) => this.authFetch({
        url: 'sale-return/getSaleReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })


    approveSaleReturnBy = (data) => this.authFetch({
        url: 'sale-return/approveSaleReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleReturnBy = (data) => this.authFetch({
        url: 'sale-return/updateSaleReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleReturn = (data) => this.authFetch({
        url: 'sale-return/insertSaleReturn',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleReturnByCode = (data) => this.authFetch({
        url: 'sale-return/deleteSaleReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}