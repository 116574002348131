import { ServiceFetch } from '../service-fetch'

export default class SaleOrderModel extends ServiceFetch {
    generateSaleOrderLastCode = (data) => this.authFetch({
        url: 'sale-order/generateSaleOrderLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleOrderBy = (data) => this.authFetch({
        url: 'sale-order/getSaleOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleOrderByCode = (data) => this.authFetch({
        url: 'sale-order/getSaleOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleOrderBy = (data) => this.authFetch({
        url: 'sale-order/updateSaleOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleOrder = (data) => this.authFetch({
        url: 'sale-order/insertSaleOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleOrderByCode = (data) => this.authFetch({
        url: 'sale-order/deleteSaleOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}