import { ServiceFetch } from '../service-fetch'

export default class EmergencyWithdrawalModel extends ServiceFetch {
    generateEmergencyWithdrawalLastCode = (data) => this.authFetch({
        url: "emergency-withdrawal/generateEmergencyWithdrawalLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getEmergencyWithdrawalBy = (data) => this.authFetch({
        url: "emergency-withdrawal/getEmergencyWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getEmergencyWithdrawalByCode = (data) => this.authFetch({
        url: "emergency-withdrawal/getEmergencyWithdrawalByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    requestUpdateBy = (data) => this.authFetch({
        url: "emergency-withdrawal/requestUpdateBy",
        method: "POST",
        body: JSON.stringify(data),
    })
    
    approveEmergencyWithdrawalBy = (data) => this.authFetch({
        url: "emergency-withdrawal/approveEmergencyWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateEmergencyWithdrawalBy = (data) => this.authFetch({
        url: "emergency-withdrawal/updateEmergencyWithdrawalBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSignatureByCode = (data) => this.authFetch({
        url: "emergency-withdrawal/updateSignatureByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertEmergencyWithdrawal = (data) => this.authFetch({
        url: "emergency-withdrawal/insertEmergencyWithdrawal",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteEmergencyWithdrawalByCode = (data) => this.authFetch({
        url: "emergency-withdrawal/deleteEmergencyWithdrawalByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}