import { ServiceFetch } from '../service-fetch'

export default class ReportDebtorModel extends ServiceFetch {
    getReportDebtorBy = (data) => this.authFetch({
        url: 'report-debtor/getReportDebtorBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportSaleOrderBy = (data) => this.authFetch({
        url: 'report-debtor/getReportSaleOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportBillingNoteCustomerBy = (data) => this.authFetch({
        url: 'report-debtor/getReportBillingNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportInvoiceCustomerBy = (data) => this.authFetch({
        url: 'report-debtor/getReportInvoiceCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportCreditNoteCustomerBy = (data) => this.authFetch({
        url: 'report-debtor/getReportDebitNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportDebitNoteCustomerBy = (data) => this.authFetch({
        url: 'report-debtor/getReportDebitNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportDebtorInvoiceBy = (data) => this.authFetch({
        url: 'report-debtor/getReportDebtorInvoiceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportDebtorPayableBy = (data) => this.authFetch({
        url: 'report-debtor/getReportDebtorPayableBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReportFinanceDebitBy = (data) => this.authFetch({
        url: 'report-debtor/getReportFinanceDebitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}