import { ServiceFetch } from '../service-fetch'

export default class DayOffModel extends ServiceFetch {
    generateDayOffLastCode = (data) => this.authFetch({
        url: 'day-off/generateDayOffLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDayOffBy = (data) => this.authFetch({
        url: 'day-off/getDayOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDayOffByCode = (data) => this.authFetch({
        url: 'day-off/getDayOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDayOffBy = (data) => this.authFetch({
        url: 'day-off/updateDayOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDayOff = (data) => this.authFetch({
        url: 'day-off/insertDayOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDayOffByCode = (data) => this.authFetch({
        url: 'day-off/deleteDayOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}