import { ServiceFetch } from '../service-fetch'

export default class ProductStockModel extends ServiceFetch {
    getProductStockBy = (data) => this.authFetch({
        url: 'product-stock/getProductStockBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductStockBalanceBy = (data) => this.authFetch({
        url: 'product-stock/getProductStockBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}