import { ServiceFetch } from '../service-fetch'

export default class CreditNoteSupplierModel extends ServiceFetch {
    generateCreditNoteSupplierLastCode = (data) => this.authFetch({
        url: 'credit-note-supplier/generateCreditNoteSupplierLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteSupplierBy = (data) => this.authFetch({
        url: 'credit-note-supplier/getCreditNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteSupplierByCode = (data) => this.authFetch({
        url: 'credit-note-supplier/getCreditNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelCreditNoteSupplierBy = (data) => this.authFetch({
        url: "credit-note-supplier/unCancelCreditNoteSupplierBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateCreditNoteSupplierBy = (data) => this.authFetch({
        url: 'credit-note-supplier/updateCreditNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCreditNoteSupplier = (data) => this.authFetch({
        url: 'credit-note-supplier/insertCreditNoteSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    requestDeleteCreditNoteSupplierByCode = (data) => this.authFetch({
        url: 'credit-note-supplier/requestDeleteCreditNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCreditNoteSupplierByCode = (data) => this.authFetch({
        url: 'credit-note-supplier/deleteCreditNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}