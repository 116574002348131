import { ServiceFetch } from '../service-fetch'

export default class ReceiptPaymentListModel extends ServiceFetch {
    getReceiptPaymentListBy = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptCopyPaymentListBy = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptCopyPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptPaymentListByCode = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptPaymentListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCheckReceiptPaymentList = (data) => this.authFetch({
        url: 'receipt-payment-list/getAdviseCheckReceiptPaymentList',
        method: 'POST',
        body: JSON.stringify(data),
    })

    verifyReceiptPaymentListByCode = (data) => this.authFetch({
        url: 'receipt-payment-list/verifyReceiptPaymentListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}