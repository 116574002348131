import { ServiceFetch } from '../service-fetch'
import {
    TimeWorkListModel,
    LeaveModel,
    OverTimeWorkListModel,
    ChangeDayOffModel,
    DayOffUserModel,
    DepartmentModel,
    PostponeBreakModel,
} from '../../models'

import { numberFunc } from "../../utils"

export default class WorkingTimeModel extends ServiceFetch {
    generateWorkingTimeLastCode = (data) => this.authFetch({
        url: 'working-time/generateWorkingTimeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getWorkingTimeBy = (data) => this.authFetch({
        url: 'working-time/getWorkingTimeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getWorkingTimeByCode = (data) => this.authFetch({
        url: 'working-time/getWorkingTimeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertWorkingTime = (data) => this.authFetch({
        url: 'working-time/insertWorkingTime',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateWorkingTime = (data) => this.authFetch({
        url: 'working-time/updateWorkingTime',
        method: 'POST',
        body: JSON.stringify(data),
    })

    calculateWorkingTime = async ({
        day_offs,
        shift_times,
        shift_days,
        working_times,
        month_select,
        year_select,
        users,
        user_code,
        time_works,
        over_times,
        date_start,
        date_end,
    }) => {
        const leave_model = new LeaveModel()
        const day_off_user_model = new DayOffUserModel()
        const time_work_list_model = new TimeWorkListModel()
        const change_day_off_model = new ChangeDayOffModel()
        const over_time_work_list_model = new OverTimeWorkListModel()
        const department_model = new DepartmentModel()
        const postpone_break_model = new PostponeBreakModel()

        const departments = await department_model.getDepartmentBy()
        let report_times = user_code === '' ? users : users.filter(e => e.employee_code === user_code)

        const d_offs = day_offs.map(e => e.day_off_date)

        let ot_times = []
        let report_users = []
        let my_ot = []

        for (const ot of over_times) {
            let over_time_work_lists = await over_time_work_list_model.getOverTimeWorkListBy({ over_time_work_code: ot.over_time_work_code })

            if (over_time_work_lists.data.length) {
                over_time_work_lists.data.forEach(item => {
                    item.over_time_work_date_start_string = ot.over_time_work_date_start
                    item.over_time_work_date_end_string = ot.over_time_work_date_end

                    item.over_time_work_list_start = ot.over_time_work_time_start
                    item.over_time_work_list_end = ot.over_time_work_time_end
                    item.over_time_work_list_times = ot.over_time_work_times
                })

                my_ot.push(...over_time_work_lists.data)
            }
        }

        let month_total = 1

        if ((date_start != '' && date_end != '')) {
            month_total = 1
        } else {
            month_total = month_select === '' ? 12 : 1
        }

        for (let i = 0; i < month_total; i++) report_users.push([])

        for (let i = 0; i < month_total; i++) {

            let month_idx = ''
            let d_end = ''
            let d_start = ''

            if (date_start != '' && date_end != '') {
                month_idx = new Date(date_start).getMonth() + 1
                d_end = new Date(date_end);
                d_start = new Date(date_start)
            } else {
                month_idx = month_select === '' ? i + 1 : month_select
                d_end = new Date(year_select, month_idx, 0);
                d_start = new Date(year_select.toString() + ' ' + month_idx + ' 1')
            }

            const d_start_num = d_start.getDay() // 0
            const count_day = d_end.getDate() // 31   

            for (const user of report_times) {
                const change_day_off = await change_day_off_model.getChangeDayOffBy({
                    user_code: user.user_code,
                    user_code_change: user.user_code,
                    change_day_off_status: 'approve',
                    user_code_status: 'OR',
                })

                const leaves = await leave_model.getLeaveBy({
                    user_code: user.user_code,
                    leave_status: 'approve',
                })

                let m_select = ''

                if (date_start != '' && date_end != '') {
                    m_select = new Date(date_start).getMonth() + 1
                    year_select = new Date(date_start).getFullYear()
                } else {
                    m_select = month_select ? month_select : (i + 1)
                }

                const day_off_users = await day_off_user_model.getDayOffUserByCode({
                    user_code: user.user_code,
                    day_off_year: year_select,
                })

                const postpone_breaks = await postpone_break_model.getPostponeBreakBy({
                    year_select,
                    user_code: user.user_code,
                    postpone_break_approve_status: 'approve',
                })

                const my_working_time = working_times.filter(e => e.user_code === user.user_code)

                const my_over_times = my_ot.filter(ot => ot.user_code === user.user_code)

                let c_absent = 0
                let c_leave = 0
                let c_late = 0
                let c_work = 0
                let c_total_work = 0
                let c_date = 1

                if (date_start != '' && date_end != '') {
                    c_date = new Date(date_start).getDate()
                }

                let c_wait = 0
                let c_ot = 0
                let c_start_date = d_start_num

                let date_absents = []
                let date_leaves = []
                let date_lates = []
                let date_works = []
                let date_ots = []
                let date_all_day_ots = []
                let list_leaves = []
                let date_time_works = []
                let date_time_lates = []
                let date_total_works = []
                let date_total_work_status = []
                let date_total_time_works = []
                let date_time_ots = []
                let date_time_hr_ots = []
                let late_time = 0

                let date_finish_work_before = []

                while (c_date <= count_day) {

                    const string_d = c_date < 10 ? '0' + c_date : c_date.toString()
                    const string_m = month_idx < 10 ? '0' + month_idx : month_idx.toString()
                    let string_y = year_select.toString()

                    if (date_start != '' && date_end != '') {
                        string_y = new Date(date_start).getFullYear().toString()
                    }

                    const now = string_y + '-' + string_m + '-' + string_d

                    const d_today = new Date().getTime()
                    const d_now = new Date(now).getTime()
                    const d_day_now = new Date(now).getDay() + 1
                    // d_day_now = 1-7 อาทิตย์ - เสาร์

                    const more_than_today = d_now > d_today
                    const old_date = d_now < d_today

                    // วันหยุดของพนักงาน
                    const day_off_user = day_off_users.data.find(item => item.day_off === now)

                    // ใบเลื่อนเบรค

                    if (!day_off_user && !d_offs.includes(now) && !more_than_today) {

                        let today_ot = my_over_times.map(e => {
                            e.d_now = new Date(now).getTime()
                            e.ot_start_time = new Date(e.over_time_work_date_start).getTime()
                            e.ot_end_time = new Date(e.over_time_work_date_end).getTime()

                            return e
                        }).filter(e => (e.d_now >= e.ot_start_time && e.d_now <= e.ot_end_time))

                        // เช็คว่าถ้ามากกว่าวันที่ปัจจุบัน ให้เป็น 0 ในขาดแทน 
                        let leave_dates = leaves.data.map(e => {
                            e.d_now = new Date(now).getTime()
                            e.d_leave_start = new Date(e.leave_date_start).getTime()
                            e.d_leave_end = new Date(e.leave_date_end).getTime()

                            return e
                        }).filter(e => (e.d_now >= e.d_leave_start && e.d_now <= e.d_leave_end) && !d_offs.includes(now))

                        // ใบเลื่อนเบรค
                        const postpone_break = postpone_breaks.data.find(item => item.postpone_break_date === now && item.postpone_break_type === 'break')

                        // ใบลาระหว่างวัน
                        const postpone_leave = postpone_breaks.data.find(item => item.postpone_break_date === now && item.postpone_break_type === 'leave')
                        // ลาเช้า = mor, ลาบ่าย = aft

                        let department = departments.data.find(e => e.department_name_en === user.department_name_en)

                        if (postpone_leave) {
                            // if (postpone_leave.postpone_break_time === 'mor') {
                            //     department.working_time_in = '00:00'
                            //     department.working_time_out = '00:00'
                            // } else if(postpone_leave.postpone_break_time === 'aft'){
                            //     department.working_time_in_2 = '00:00'
                            //     department.working_time_out_2 = '00:00'
                            // }
                        }

                        if (my_working_time.length && department) {
                            const t_start = d_day_now === 1 ? '08:00' : department.working_time_in.split(':');
                            const t_end = postpone_break ? postpone_break.postpone_break_time_out.split(':') : department.working_time_out.split(':');

                            const t_start_2 = postpone_break ? postpone_break.postpone_break_time_in.split(':') : department.working_time_in_2.split(':');
                            const t_end_2 = d_day_now === 1 ? '17:00' : department.working_time_out_2.split(':');

                            const my_work = my_working_time.find(date => date.working_time_date === now)

                            if (my_work && leave_dates.length === 0) {

                                const my_time_outs = my_work.working_time_out.split(':')
                                const my_time_ins = my_work.working_time_in.split(':')

                                const my_time_outs_2 = my_work.working_time_out_2.split(':')
                                const my_time_ins_2 = my_work.working_time_in_2.split(':')

                                const ot_time_starts = today_ot.length ? today_ot[0].over_time_work_list_start.split(':') : []
                                const ot_time_ends = today_ot.length ? today_ot[0].over_time_work_list_end.split(':') : []

                                const t_hr_in = numberFunc.toInt(t_start[0])
                                const t_min_in = numberFunc.toInt(t_start[1])

                                const t_hr_out = numberFunc.toInt(t_end[0])
                                const t_min_out = numberFunc.toInt(t_end[1])

                                const t_hr_in_2 = numberFunc.toInt(t_start_2[0])
                                const t_min_in_2 = numberFunc.toInt(t_start_2[1])

                                const t_hr_out_2 = numberFunc.toInt(t_end_2[0])
                                const t_min_out_2 = numberFunc.toInt(t_end_2[1])

                                const my_hr_in = numberFunc.toInt(my_time_ins[0])
                                const my_min_in = numberFunc.toInt(my_time_ins[1])

                                const my_hr_out = numberFunc.toInt(my_time_outs[0])
                                const my_min_out = numberFunc.toInt(my_time_outs[1])

                                const my_hr_in_2 = numberFunc.toInt(my_time_ins_2[0])
                                const my_min_in_2 = numberFunc.toInt(my_time_ins_2[1])

                                const my_hr_out_2 = numberFunc.toInt(my_time_outs_2[0])
                                const my_min_out_2 = numberFunc.toInt(my_time_outs_2[1])

                                //  เข้างานปกติทั้งเช้าและบ่าย
                                if (!postpone_leave || (my_hr_in < t_hr_in || (my_hr_in === t_hr_in && my_min_in <= t_min_in)) && (my_hr_in_2 < t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && (my_min_in_2 <= t_min_in_2)))) {
                                    //  OT 
                                    if (today_ot.length) {
                                        const ot_hr = this._calculateOT(
                                            my_hr_out,
                                            numberFunc.toInt(ot_time_starts[0]),
                                            numberFunc.toInt(my_min_out_2),
                                            numberFunc.toInt(ot_time_starts[1]),
                                            numberFunc.toInt(ot_time_ends[1]),
                                            numberFunc.toInt(ot_time_ends[0]),
                                        )

                                        if (ot_hr > 0) {
                                            date_ots.push(now)
                                            date_time_ots.push(today_ot[0].over_time_work_list_start + ' - ' + my_work.working_time_out + ' น.')
                                            date_time_hr_ots.push(ot_hr)
                                            ot_times.push({
                                                over_time_times: today_ot[0].over_time_work_list_times,
                                                ot_hr: ot_hr,
                                                type: 'hr',
                                            })
                                            c_ot += ot_hr
                                        }
                                    }

                                    // มาทำงานปกติ
                                    c_work++
                                    date_works.push(now)
                                    date_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                    // date_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')
                                    date_total_work_status.push({
                                        text: 'มา',
                                        text_color: 'success',
                                    })

                                    date_total_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                    // date_total_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')

                                    date_total_works.push(now);
                                    c_total_work++
                                    // เลิกงานก่อนเวลา 
                                    if (
                                        // ช่วงเช้า
                                        ((my_hr_out < t_hr_out) || (my_hr_out === t_hr_out && my_min_out < t_min_out)) ||

                                        // ช่วงบ่าย
                                        (my_hr_out_2 < t_hr_out_2) || (my_hr_out_2 === t_hr_out_2 && my_min_out_2 < t_min_out_2) ||

                                        // ไม่สแกนช่วงบ่าย
                                        (my_time_ins_2.length === 0 && my_time_outs_2.length === 0)
                                    ) {
                                        date_finish_work_before.push({
                                            time_work_end: postpone_break ? postpone_break.postpone_break_time_out : department.working_time_out,
                                            my_work_end: my_work.working_time_out,

                                            time_work_end_2: d_day_now === 1 ? '17:00' : department.working_time_out_2,
                                            my_work_end_2: my_work.working_time_out_2,
                                        })
                                    }
                                    // มาสาย
                                } else if (postpone_leave || ((my_hr_in > t_hr_in || (my_hr_in === t_hr_in && my_min_in > t_min_in)) || ((my_hr_in_2 > t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && my_min_in_2 > t_min_in_2))))
                                ) {

                                    let late_time_total = 0

                                    if (postpone_leave) {
                                        c_absent += 0.5
                                    }

                                    if (my_hr_in > t_hr_in || (my_hr_in === t_hr_in && my_min_in > t_min_in)) {

                                        const time1 = new Date()
                                        const time2 = new Date()

                                        time1.setHours(t_hr_in, t_min_in, 0, 0)
                                        time2.setHours(my_hr_in, my_min_in, 0, 0)

                                        late_time_total += (time2 - time1) / (1000 * 60)
                                    }

                                    if ((my_hr_in_2 > t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && my_min_in_2 > t_min_in_2))) {

                                        const time1 = new Date()
                                        const time2 = new Date()

                                        time1.setHours(t_hr_in_2, t_min_in_2, 0, 0)
                                        time2.setHours(my_hr_in_2, my_min_in_2, 0, 0)

                                        late_time_total += (time2 - time1) / (1000 * 60)

                                    }
                                    // มาทำงานสาย 
                                    late_time += late_time_total
                                    // late_time += numberFunc.toInt(my_time_ins[1]) - numberFunc.toInt(t_start[1]) 

                                    c_late++
                                    date_lates.push(now)
                                    date_time_lates.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                    // date_time_lates.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')
                                    date_total_work_status.push({
                                        text: 'สาย',
                                        text_color: 'warning',
                                    })

                                    date_total_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                    // date_total_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')

                                    if (today_ot.length) {
                                        const ot_hr = this._calculateOT(
                                            numberFunc.toInt(my_time_outs[0]),
                                            numberFunc.toInt(ot_time_starts[0]),
                                            numberFunc.toInt(my_time_outs[1]),
                                            numberFunc.toInt(ot_time_starts[1]),
                                            numberFunc.toInt(ot_time_ends[1]),
                                            numberFunc.toInt(ot_time_ends[0]),
                                        )

                                        if (ot_hr > 0) {
                                            date_ots.push(now)
                                            date_time_ots.push(today_ot[0].over_time_work_list_start + ' - ' + my_work.working_time_out + ' น.')
                                            date_time_hr_ots.push(ot_hr)
                                            ot_times.push({
                                                over_time_times: today_ot[0].over_time_work_list_times,
                                                ot_hr: ot_hr,
                                                type: 'hr',
                                            })
                                            c_ot += ot_hr
                                        }
                                    }

                                    // เลิกงานก่อนเวลา 
                                    if (
                                        // ช่วงเช้า
                                        ((my_hr_out < t_hr_out) || (my_hr_out === t_hr_out && my_min_out < t_min_out)) ||

                                        // ช่วงบ่าย
                                        (my_hr_out_2 < t_hr_out_2) || (my_hr_out_2 === t_hr_out_2 && my_min_out_2 < t_min_out_2) ||

                                        // ไม่สแกนช่วงบ่าย
                                        (my_time_ins_2.length === 0 && my_time_outs_2.length === 0)
                                    ) {
                                        date_finish_work_before.push({
                                            time_work_end: postpone_break ? postpone_break.postpone_break_time_out : department.working_time_out,
                                            my_work_end: my_work.working_time_out,

                                            time_work_end_2: d_day_now === 1 ? '17:00' : department.working_time_out_2,
                                            my_work_end_2: my_work.working_time_out_2,
                                        })
                                    }
                                    date_total_works.push(now);
                                    c_total_work++
                                }

                            } else if (leave_dates.length) {
                                c_leave++
                                date_leaves.push(now)
                                list_leaves.push(leave_dates[0].leave_code)
                                date_total_work_status.push({
                                    text: 'ลา',
                                    text_color: 'warning',
                                })
                                date_total_time_works.push('-')

                                date_total_works.push(now);
                                c_total_work++
                            } else {
                                let change_day_off_data = change_day_off.data.find(i =>
                                    (i.user_code === user.user_code && i.user_change_date === now))

                                if (!change_day_off_data) {
                                    c_absent++
                                    date_absents.push(now)
                                    date_total_work_status.push({
                                        text: 'ไม่ลา',
                                        text_color: 'danger',
                                    })
                                    date_total_time_works.push('-')

                                    date_total_works.push(now);
                                    c_total_work++
                                }
                            }
                        }
                    } else {
                        // const my_work = my_working_time.find(date => date.working_time_date === now)  
                        // console.log('my_over_times ; ',my_over_times);
                        // // โอทีในวันหยุดประจำ และวันหยุดทั่วไป
                        // if ((day_off_user || d_offs.includes(now)) && my_work) {
                        //     let today_ot = my_over_times.map(e => {
                        //         e.d_now = new Date(now).getTime()
                        //         e.ot_start_time = new Date(e.over_time_work_date_start).getTime()
                        //         e.ot_end_time = new Date(e.over_time_work_date_end).getTime() 

                        //         return e
                        //     }).filter(e => (e.d_now >= e.ot_start_time && e.d_now <= e.ot_end_time))

                        //     const my_time_outs = my_work.working_time_out.split(':')
                        //     const my_time_ins = my_work.working_time_in.split(':') 

                        //     const ot_time_starts =  today_ot.length ? today_ot[0].over_time_work_list_start.split(':') : []
                        //     const ot_time_ends =  today_ot.length ? today_ot[0].over_time_work_list_end.split(':') : []

                        //     if (today_ot.length) { 
                        //         const ot_hr = this._calculateOT(
                        //             numberFunc.toInt(my_time_outs[0]),
                        //             numberFunc.toInt(ot_time_starts[0]),
                        //             numberFunc.toInt(my_time_outs[1]),
                        //             numberFunc.toInt(ot_time_starts[1]),
                        //             numberFunc.toInt(ot_time_ends[1]),
                        //             numberFunc.toInt(ot_time_ends[0]),
                        //             )

                        //             if (ot_hr > 0) { 
                        //                 date_ots.push(now)
                        //                 date_time_ots.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')
                        //                 date_time_hr_ots.push(ot_hr)
                        //                 ot_times.push({
                        //                     over_time_times: today_ot[0].over_time_work_list_times,
                        //                     ot_hr: ot_hr,
                        //                 })
                        //                 c_ot += ot_hr
                        //             }
                        //         }
                        // }
                        const my_work = my_working_time.find(date => date.working_time_date === now)

                        let today_ot = my_over_times.map(e => {
                            e.d_now = new Date(now).getTime()
                            e.ot_start_time = new Date(e.over_time_work_date_start).getTime()
                            e.ot_end_time = new Date(e.over_time_work_date_end).getTime()

                            return e
                        }).filter(e => (e.d_now >= e.ot_start_time && e.d_now <= e.ot_end_time))

                        if ((day_off_user || d_offs.includes(now)) && my_work && today_ot.length) {
                            const postpone_break = postpone_breaks.data.find(item => item.postpone_break_date === now && item.postpone_break_type === 'break')
                            const department = departments.data.find(e => e.department_name_en === user.department_name_en)

                            // โอทีในวันหยุดประจำ และวันหยุดทั่วไป

                            const t_start = d_day_now === 1 ? '08:00' : department.working_time_in.split(':');
                            const t_end = postpone_break ? postpone_break.postpone_break_time_out.split(':') : department.working_time_out.split(':');

                            const t_start_2 = postpone_break ? postpone_break.postpone_break_time_in.split(':') : department.working_time_in_2.split(':');
                            const t_end_2 = d_day_now === 1 ? '17:00' : department.working_time_out_2.split(':');

                            const my_time_outs = my_work.working_time_out.split(':')
                            const my_time_ins = my_work.working_time_in.split(':')

                            const my_time_outs_2 = my_work.working_time_out_2.split(':')
                            const my_time_ins_2 = my_work.working_time_in_2.split(':')

                            const t_hr_in = numberFunc.toInt(t_start[0])
                            const t_min_in = numberFunc.toInt(t_start[1])

                            const t_hr_out = numberFunc.toInt(t_end[0])
                            const t_min_out = numberFunc.toInt(t_end[1])

                            const t_hr_in_2 = numberFunc.toInt(t_start_2[0])
                            const t_min_in_2 = numberFunc.toInt(t_start_2[1])

                            const t_hr_out_2 = numberFunc.toInt(t_end_2[0])
                            const t_min_out_2 = numberFunc.toInt(t_end_2[1])

                            const my_hr_in = numberFunc.toInt(my_time_ins[0])
                            const my_min_in = numberFunc.toInt(my_time_ins[1])

                            const my_hr_out = numberFunc.toInt(my_time_outs[0])
                            const my_min_out = numberFunc.toInt(my_time_outs[1])

                            const my_hr_in_2 = numberFunc.toInt(my_time_ins_2[0])
                            const my_min_in_2 = numberFunc.toInt(my_time_ins_2[1])

                            const my_hr_out_2 = numberFunc.toInt(my_time_outs_2[0])
                            const my_min_out_2 = numberFunc.toInt(my_time_outs_2[1])

                            date_all_day_ots.push({
                                times: today_ot[0].over_time_work_times,
                                now
                            })
                            //  เข้างานปกติทั้งเช้าและบ่าย
                            if ((my_hr_in < t_hr_in || (my_hr_in === t_hr_in && my_min_in <= t_min_in)) && (my_hr_in_2 < t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && (my_min_in_2 <= t_min_in_2)))) {
                                //  OT 

                                // มาทำงานปกติ
                                c_work++
                                date_works.push(now)
                                date_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                // date_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')
                                date_total_work_status.push({
                                    text: 'มา',
                                    text_color: 'success',
                                })

                                date_total_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                // date_total_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')

                                date_total_works.push(now);
                                c_total_work++
                                // เลิกงานก่อนเวลา 
                                if (
                                    // ช่วงเช้า
                                    ((my_hr_out < t_hr_out) || (my_hr_out === t_hr_out && my_min_out < t_min_out)) ||

                                    // ช่วงบ่าย
                                    (my_hr_out_2 < t_hr_out_2) || (my_hr_out_2 === t_hr_out_2 && my_min_out_2 < t_min_out_2) ||

                                    // ไม่สแกนช่วงบ่าย
                                    (my_time_ins_2.length === 0 && my_time_outs_2.length === 0)
                                ) {
                                    date_finish_work_before.push({
                                        time_work_end: postpone_break ? postpone_break.postpone_break_time_out : department.working_time_out,
                                        my_work_end: my_work.working_time_out,

                                        time_work_end_2: d_day_now === 1 ? '17:00' : department.working_time_out_2,
                                        my_work_end_2: my_work.working_time_out_2,
                                    })
                                }
                                // มาสาย
                            } else if (((my_hr_in > t_hr_in || (my_hr_in === t_hr_in && my_min_in > t_min_in)) || ((my_hr_in_2 > t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && my_min_in_2 > t_min_in_2))))
                            ) {
                                let late_time_total = 0

                                if (my_hr_in > t_hr_in || (my_hr_in === t_hr_in && my_min_in > t_min_in)) {

                                    const time1 = new Date()
                                    const time2 = new Date()

                                    time1.setHours(t_hr_in, t_min_in, 0, 0)
                                    time2.setHours(my_hr_in, my_min_in, 0, 0)

                                    late_time_total += (time2 - time1) / (1000 * 60)
                                }

                                if ((my_hr_in_2 > t_hr_in_2 || (my_hr_in_2 === t_hr_in_2 && my_min_in_2 > t_min_in_2))) {

                                    const time1 = new Date()
                                    const time2 = new Date()

                                    time1.setHours(t_hr_in_2, t_min_in_2, 0, 0)
                                    time2.setHours(my_hr_in_2, my_min_in_2, 0, 0)

                                    late_time_total += (time2 - time1) / (1000 * 60)

                                }
                                // มาทำงานสาย 
                                late_time += late_time_total
                                // late_time += numberFunc.toInt(my_time_ins[1]) - numberFunc.toInt(t_start[1]) 

                                c_late++
                                date_lates.push(now)
                                date_time_lates.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                // date_time_lates.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')
                                date_total_work_status.push({
                                    text: 'สาย',
                                    text_color: 'warning',
                                })

                                date_total_time_works.push('( ' + my_work.working_time_in + ' - ' + my_work.working_time_out + ' น. ) - ( ' + my_work.working_time_in_2 + ' - ' + my_work.working_time_out_2 + ' น. )')
                                // date_total_time_works.push(my_work.working_time_in + ' - ' + my_work.working_time_out + ' น.')


                                // เลิกงานก่อนเวลา 
                                if (
                                    // ช่วงเช้า
                                    ((my_hr_out < t_hr_out) || (my_hr_out === t_hr_out && my_min_out < t_min_out)) ||

                                    // ช่วงบ่าย
                                    (my_hr_out_2 < t_hr_out_2) || (my_hr_out_2 === t_hr_out_2 && my_min_out_2 < t_min_out_2) ||

                                    // ไม่สแกนช่วงบ่าย
                                    (my_time_ins_2.length === 0 && my_time_outs_2.length === 0)
                                ) {
                                    date_finish_work_before.push({
                                        time_work_end: postpone_break ? postpone_break.postpone_break_time_out : department.working_time_out,
                                        my_work_end: my_work.working_time_out,

                                        time_work_end_2: d_day_now === 1 ? '17:00' : department.working_time_out_2,
                                        my_work_end_2: my_work.working_time_out_2,
                                    })
                                }
                                date_total_works.push(now);
                                c_total_work++
                            }
                        }
                    }

                    if (c_start_date > 5) {
                        c_start_date = 0
                    } else {
                        c_start_date++
                    }

                    c_date++
                }

                user.c_work = c_work
                user.c_leave = c_leave
                user.c_late = c_late
                user.c_ot = c_ot
                user.c_absent = c_absent
                user.c_wait = c_wait
                user.c_total_work = c_total_work
                user.finish_work_before = date_finish_work_before


                report_users[i].push({
                    user_code: user.user_code,
                    user_full_name: user.user_full_name,
                    user_position_name: user.user_position_name,
                    user_profile_image: user.user_profile_image,
                    c_total_work: user.c_total_work,
                    c_absent: user.c_absent,
                    c_leave: user.c_leave,
                    c_work: user.c_work,
                    c_late: user.c_late,
                    c_ot: user.c_ot,
                    c_wait: user.c_wait,
                    finish_work_before: user.finish_work_before,
                    date_absents: date_absents,
                    date_leaves: date_leaves,
                    list_leaves: list_leaves,
                    date_lates: date_lates,
                    date_works: date_works,
                    date_ots: date_ots,
                    date_time_works: date_time_works,
                    date_time_lates: date_time_lates,
                    date_time_ots: date_time_ots,
                    date_time_hr_ots: date_time_hr_ots,
                    date_total_works: date_total_works,
                    date_total_work_status: date_total_work_status,
                    date_total_time_works: date_total_time_works,
                    ot_times: ot_times,
                    late_time: late_time,
                    date_all_day_ots: date_all_day_ots,
                })
            }
        }

        report_users.forEach(reports => {
            let c_total_absent = 0
            let c_total_leave = 0
            let c_total_work = 0
            let c_total_late = 0
            let c_total_wait = 0
            let c_total_ot = 0

            for (const e of reports) {
                c_total_absent += e.c_absent
                c_total_leave += e.c_leave
                c_total_work += e.c_work
                c_total_late += e.c_late
                c_total_wait += e.c_wait
                c_total_ot += e.c_ot
            }

            reports.c_total_absent = c_total_absent
            reports.c_total_leave = c_total_leave
            reports.c_total_work = c_total_work
            reports.c_total_late = c_total_late
            reports.c_total_wait = c_total_wait
            reports.c_total_ot = c_total_ot
        })

        return report_users
    }

    _calculateOT = (t_out0, t_ot_st0, t_out1, t_ot_st1, t_ot_ed1, t_ot_ed0) => {
        let ot_hr = 0

        if (t_out0 >= t_ot_st0 && ((t_out0 === t_ot_st0 && t_out1 >= t_ot_st1) || t_out0 >= t_ot_st0)) {
            if (t_out0 > t_ot_ed0) {
                t_out0 = t_ot_ed0
                t_out1 = t_ot_ed1
            } else if (t_out0 === t_ot_ed0 && t_out1 > t_ot_ed1) {
                t_out1 = t_ot_ed1
            }

            ot_hr = (((t_out0 - t_ot_st0) * 60) + Math.abs(t_out1 - t_ot_st1)) / 60
        }

        return numberFunc.toFloat(numberFunc.decimalFix(ot_hr, 2))
    }
}

